<template>
  <div class="service" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div class="service_icon"></div>
    <div class="service_box" :style="show?'display:block':''"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      show:false
    };
  },
  created() {},
  computed: {},
  methods: {
    handleMouseEnter() {
      this.onShow()
    },
    handleMouseLeave() {
      this.onHide()
    },
    onShow(){
      this.show = true
    },
    onHide(){
      this.show = false
    }
  },
};
</script>

<style scoped lang="scss">
.service {
  position: fixed;
  top: 50%; /* 顶部对齐到 50% 位置 */
  transform: translateY(-50%); /* 向上移动自身高度的 50% */
  z-index: 100;
  right: 10px; /* 距离右侧 10px */

  &_icon {
    width: 66px;
    height: 66px;
    background-image: url(~@/static/new/img/service/service_bg2.png);
    background-size: 100%;
  }

  &_box {
    width: 191px;
    height: 252px;
    position: absolute;
    right: 66px;
    // bottom: -77px;
    top: 0px;
    background-image: url(~@/static/new/img/service/service_bg.webp);
    background-size: 100%;
    display: none;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 20px);
      z-index: -1;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
