import { mapState, mapMutations, mapActions } from "vuex";
import agreement from "./components/agreement.vue";
import loginInput from "./components/loginInput.vue";
export default {
  components: {
    agreement,
    loginInput,
  },
  computed: {
    ...mapState("login", ["active", "agreementState"]),
  },
  methods: {
    ...mapActions("login", ["setActive",'agreementRules']),
    async getCode() {
      await this.postCode();
      this.$refs.codeIpt.secondrun();
    },
    setError(ref,msssage){
      this.$refs[ref].setError(msssage);
    },
    phoneRules(){
      if (this.from.phone.length !== 11) {
        this.setError('phoneIpt','请输入正确的手机号')
        return Promise.reject();
      }
    },
    codeRules(){
      if (this.from.verifyCode.length !== 4) {
        this.setError('codeIpt','请输入正确的验证码')
        return Promise.reject();
      }
    },
    passwordRules(){
      if (!this.from.password) {
        this.setError('passowrdIpt','请输入正确的密码')
        return Promise.reject();
      }
    },
    async postCode() {
      await this.phoneRules()
      const { status, info } = await this.codeApi({ phone: this.from.phone });
      if (status === 200) {
        this.$message({
          message: info,
          type: "success",
        });
        return Promise.resolve();
      } else {
        this.$message({
          message: info,
          type: "error",
        });
        return Promise.reject();
      }
    },
    setUserInfo(data) {
      this.$store.dispatch("user/setUserInfo", data)
      if (this.$route.query.redirect) {
        let redirect = this.$route.query.redirect;
        this.$router.push(redirect);
      } else {
        this.$router.push('/');
      }
    },
  },
};
