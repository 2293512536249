<!-- 新手礼包 -->
<template>
  <div class="signInMode">
    <el-dialog :show-close="false" :visible.sync="dialogVisible" width="960px" :lock-scroll="false" :modal-append-to-body="false">
      <div class="signInMode_diaog">
        <div class="signInMode_diaog_top">
          <div class="left">
            <div class="left_t">签到领劵</div>
            <div class="left_b">每日领取“合同代金劵”持续90天</div>
          </div>
          <div class="right">{{ singDate }}</div>
        </div>
        <transition name="el-fade-in-linear">
          <div v-show="show" class="signInMode_diaog_content">
            <div class="signInMode_diaog_content_header">
              <div class="left">已领取 <span class="red">{{ singSum }}</span> 张</div>
              <div class="right">（剩余 <span class="red">{{ noSingSun }}</span> 天）</div>
            </div>
            <div class="signInMode_diaog_content_table">
              <template v-for="item in list">
                <div class="item item1" v-if="renderType(item) ==1" :key="item.checkInId">
                  <div class="item_top">第{{item.checkInId}}天</div>
                  <div class="item_center">
                    <img src="@/static/image/signIn/signIn_icon1.svg" alt="">
                  </div>
                  <div class="item_bottom">已签</div>
                </div>
                <div class="item item2" v-if="renderType(item) ==2" :key="item.checkInId">
                  <div class="item_top">第{{item.checkInId}}天</div>
                  <div class="item_center">
                    <img src="@/static/image/signIn/signIn_icon2.svg" alt="">
                  </div>
                  <div class="item_bottom">未签</div>
                </div>
                <div class="item item3" v-if="renderType(item) ==3" :key="item.checkInId" @click="sing">
                  <div class="item_top">第{{item.checkInId}}天</div>
                  <div class="item_center">
                    <img src="@/static/image/signIn/signIn_icon3.svg" alt="">
                  </div>
                  <div class="item_bottom">点击签到</div>
                </div>

                <div class="item item4" v-if="renderType(item) ==4" :key="item.checkInId">
                  <div class="item_top">第{{item.checkInId}}天</div>
                  <div class="item_center">
                    <img src="@/static/image/signIn/signIn_icon2.svg" alt="">
                  </div>
                  <div class="item_bottom">签到领券</div>
                </div>
              </template>
              <!-- 分页按钮 -->
              <div class="lastbutton1" v-if="page==1" @click="step('next')">
                <p style="margin-right:4px">下一页</p>

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8 1.14286C11.7857 1.14286 14.8571 4.21429 14.8571 8C14.8571 11.7857 11.7857 14.8571 8 14.8571C4.21429 14.8571 1.14286 11.7857 1.14286 8C1.14286 4.21429 4.21429 1.14286 8 1.14286ZM8 0C3.58571 0 0 3.58571 0 8C0 12.4143 3.58571 16 8 16C12.4143 16 16 12.4143 16 8C16 3.58571 12.4143 0 8 0Z"
                    fill="#0062FF" />
                  <path d="M0.5 0.502941L1.07286 0L4.5 3L1.08292 6L0.51005 5.49706L3.35427 3L0.5 0.502941Z" fill="#0062FF" transform="translate(6, 5)" />
                </svg>

              </div>
              <div class="lastbutton2" v-if="page==2">
                <div class="item" @click="step('prev')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path
                      d="M15 2.14286C7.90178 2.14286 2.14286 7.90179 2.14286 15C2.14286 22.0982 7.90178 27.8571 15 27.8571C22.0982 27.8571 27.8571 22.0982 27.8571 15C27.8571 7.90179 22.0982 2.14286 15 2.14286ZM15 0C23.2768 0 30 6.72321 30 15C30 23.2768 23.2768 30 15 30C6.72322 30 0 23.2768 0 15C0 6.72321 6.72322 0 15 0Z"
                      fill="#0062FF" />
                    <path d="M17.8125 10.318L16.7384 9.375L10.3125 15L16.7195 20.625L17.7937 19.682L12.4607 15L17.8125 10.318Z" fill="#0062FF" />
                  </svg>
                  <p>上一页</p>
                </div>
                <div class="item" @click="step('next')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path
                      d="M15 2.14286C22.0982 2.14286 27.8571 7.90179 27.8571 15C27.8571 22.0982 22.0982 27.8571 15 27.8571C7.90179 27.8571 2.14286 22.0982 2.14286 15C2.14286 7.90179 7.90179 2.14286 15 2.14286ZM15 0C6.72321 0 0 6.72321 0 15C0 23.2768 6.72321 30 15 30C23.2768 30 30 23.2768 30 15C30 6.72321 23.2768 0 15 0Z"
                      fill="#0062FF" />
                    <path d="M12.1875 10.318L13.2616 9.375L19.6875 15L13.2805 20.625L12.2063 19.682L17.5393 15L12.1875 10.318Z" fill="#0062FF" />
                  </svg>
                  <p>下一页</p>
                </div>
              </div>
              <div class="lastbutton1" v-if="page==3" @click="step('prev')">
                <p style="margin-right:4px">上一页</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8 1.14286C4.21429 1.14286 1.14286 4.21429 1.14286 8C1.14286 11.7857 4.21429 14.8571 8 14.8571C11.7857 14.8571 14.8571 11.7857 14.8571 8C14.8571 4.21429 11.7857 1.14286 8 1.14286ZM8 0C12.4143 0 16 3.58571 16 8C16 12.4143 12.4143 16 8 16C3.58571 16 0 12.4143 0 8C0 3.58571 3.58571 0 8 0Z"
                    fill="#0062FF" />
                  <path d="M9.5 5.50294L8.92714 5L5.5 8L8.91708 11L9.48995 10.4971L6.64573 8L9.5 5.50294Z" fill="#0062FF" />
                </svg>
              </div>
            </div>
          </div>
        </transition>
        <transition name="el-fade-in-linear">
          <el-button class="signInMode_diaog_button" :disabled="buttonDisabled()" v-show="show" @click="sing" type="primary">签到领取奖励</el-button>
        </transition>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const now = new Date();
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      page: 3,
      checkInList: [],
      singSum: 0,
      noSingSun: 0,
      singDate: '',
      show: true,
      todayTimestamp: Math.floor(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()) / 1000)
    };
  },
  created() { },
  computed: {
    list() {   // 分页
      if (this.page == 1) {
        return this.checkInList.slice(0, 30)
      } else if (this.page == 2) {
        return this.checkInList.slice(30, 60)
      } else {
        return this.checkInList.slice(60, 90)
      }
    },
  },
  methods: {
    onshow() {
      this.getCheckInList()
    },
    showFn() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 500);
    },
    step(type) {
      this.showFn()
      if (type == 'next') {
        this.page++
      } else {
        this.page--
      }
    },
    async getCheckInList() {
      const { data, info, status } = await this.$api.getCheckInList()
      if (status === 200) {
        this.checkInList = data;
        this.initInfo()
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    renderType(item) {
      // type == 1 已签到 type == 2 未签到  type == 3 点击签到  type == 4 未到签到时间
      const isToday = new Date(item.timeWindow * 1000).toDateString() === new Date().toDateString();
      if (item.checkedInAt) {
        return 1
      }
      if (isToday) {
        return 3
      }

      if (item.timeWindow > this.todayTimestamp) {
        return 4
      }
      return 2
    },
    initPage() {

      // 给定的数据
      const data = this.checkInList;

      // 遍历数据，找到与当前时间匹配的 timeWindow
      let page = null;
      for (const item of data) {
        if (item.timeWindow <= this.todayTimestamp && this.todayTimestamp < item.timeWindow + 86400) { // 86400秒是一天
          // 根据 checkInId 确定页面
          if (item.checkInId >= 1 && item.checkInId <= 30) {
            page = 1;
          } else if (item.checkInId >= 31 && item.checkInId <= 60) {
            page = 2;
          } else if (item.checkInId >= 61 && item.checkInId <= 90) {
            page = 3;
          }
          break; // 找到匹配项后退出循环
        }
      }
      this.page = page || 1; // 如果没有找到匹配项，默认为第一页
    },
    sing() {
      this.dialogVisible = false;
      this.$emit('sing')
    },
    buttonDisabled(){
      const todayCheckIn = this.checkInList.find(item => {
        const isToday = new Date(item.timeWindow * 1000).toDateString() === new Date().toDateString();
        return isToday && item.checkedInAt;
      });
      return !!todayCheckIn ;
    },
    // 初始化数据
    initInfo() {
      if (this.checkInList.length == 0) {
        this.$message({
          type: 'error',
          message: '暂无签到数据'
        })
        return
      }
      this.initPage();
      this.singSum = this.checkInList.filter(item => item.checkedInAt).length;
      this.noSingSun = this.checkInList.filter(item => item.timeWindow >= this.todayTimestamp && item.checkedInAt === null).length;
      this.singDate = `${this.$utils.timeFormat(this.checkInList[0].timeWindow, 'mm月dd日')}-${this.$utils.timeFormat(this.checkInList[this.checkInList.length - 1].timeWindow, 'mm月dd日')}`
      this.dialogVisible = true;

    }
  }
}
</script>

<style scoped lang='scss'>
.signInMode {
  /deep/ .el-dialog {
    background-color: rgba($color: #000000, $alpha: 0);
    box-shadow: none;
    margin-top: 0px !important;

    &__header {
      padding: 0;
    }

    &__body {
      padding: 0;
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }

  &_diaog {
    // margin-top: calc((100vh - 595px) / 2);
    margin-top: 50px;
    background-image: url(~@/static/image/signIn/signIn_bg.png);
    width: 960px;
    height: 798px;
    background-size: 960px 300px;
    background-repeat: no-repeat;
    position: relative;
    background-color: #f6f7f8;
    border-radius: 10px;
    &_top {
      height: 100px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        .left_t {
          color: #262626;
          font-family: DouyinSans;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
        .left_b {
          color: #666;
          font-family: DouyinSans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .right {
        color: #333;
        font-family: DouyinSans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &_content {
      width: 900px;
      height: 626px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: -2px 2px 0px 0px #fff inset, 2px 2px 0px 0px #fff inset,
        0px 2px 0px 0px #fff inset, 0px 6px 20px 0px rgba(0, 0, 0, 0.04);
      backdrop-filter: blur(7.5px);
      margin: 0 auto;
      &_header {
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        .left {
          color: #333;
          font-family: "Source Sans Pro";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .right {
          color: #999;
          font-family: "Source Sans Pro";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .red {
          color: #fe3766;
          font-family: "Source Sans Pro";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      &_table {
        padding: 0 30px;
        display: flex;
        gap: 10px; /* 行和列的间距都是 10px */
        flex-wrap: wrap;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 96px;
          height: 128px;
          flex-shrink: 0;
          border-radius: 10px;
          img {
            margin: 10px 0;
          }
          &_top {
          }
          &_center {
          }
          &_bottom {
          }
        }
        .item1 {
          background: #ffeef1;
          color: #000;
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .item2 {
          border-radius: 10px;
          border: 1.5px dashed #fe3766;
          background: #f6f7f8;
          color: #999;
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .item3 {
          border-radius: 10px;
          background: linear-gradient(180deg, #fd4489 0%, #fd5d6a 100%);
          box-shadow: 0px 4px 10px 0px rgba(254, 55, 102, 0.2);
          color: #fff;
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
        }
        .item4 {
          border-radius: 10px;
          background: #f6f7f8;
          color: #999;
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .lastbutton1 {
          width: 202px;
          height: 132px;
          flex-shrink: 0;
          border-radius: 10px;
          background: #f6f7f8;
          color: #0062ff;
          font-family: "Source Sans Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .lastbutton2 {
          display: flex;
          gap: 10px; /* 行和列的间距都是 10px */
          .item {
            border-radius: 10px;
            background: #f6f7f8;
            cursor: pointer;
            p {
              color: #0062ff;
              font-family: "Source Sans Pro";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-top: 10px;
            }
          }
        }
      }
    }
    &_button {
      width: 300px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 2px;
      margin: 0 auto;
      margin-top: 16px;
      color: #fff;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>