// 签到成功弹出
<template>
  <div class="sigInSuccess">
    <el-dialog :show-close="false" :visible.sync="dialogVisible" width="488px" :lock-scroll="false" :modal-append-to-body="false">
      <div class="sigInSuccess_diaog">
        <div class="btn" @click="onclose"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false
    };
  },
  created() { },
  computed: {


  },
  methods: {
    onshow() {
      this.dialogVisible = true
    },
    onclose() {
      this.dialogVisible = false
    },
  },
};
</script>

<style scoped lang='scss'>
.sigInSuccess {
  /deep/ .el-dialog {
    background-color: rgba($color: #000000, $alpha: 0);
    box-shadow: none;
    margin-top: 0px !important;

    &__header {
      padding: 0;
    }

    &__body {
      padding: 0;
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }

  &_diaog {
    // margin-top: calc((100vh - 565px) / 3);
    margin-top: 50px;
    background-image: url(~@/static/image/signIn/signIn_success.png);
    width: 488px;
    height: 565px;
    background-size: 488px 565px;
    background-repeat: no-repeat;
    position: relative;
    .btn{
      width: 200px;
      height: 50px;
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
}
</style>