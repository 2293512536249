/*
* @Author: 曹俊杰
* @Date: 2022-05-06 15:18:30
* @Module: 客服
*/
<template>
  <div class="service">
    <div class="service-top" @click="kefu">
      <el-badge :value="unreadNum" class="badge">
        <img src="@/static/service1.png" alt="" srcset="">
      </el-badge>
      <p>中能E问</p>
    </div>
    <div class="service-bottom">
      <div class="service-bottom-1" slot="reference">
        <span class="el-icon-ali-kefu"></span>
        <p>平台客服</p>
        <div style="width:176px;height:176px;" class="service-code"></div>
      </div>

      <div class="service-bottom-2" @click="$utils.help()">
        <span class="el-icon-ali-bangzhuzhongxin"></span>
        <p>帮助中心</p>
      </div>
      <div class="service-bottom-2" @click="quanyi">
        <span class="el-icon-ali-quanyizhihang1"></span>
        <p>权益执行</p>
      </div>
    </div>
    <!-- <div class="btn">
      <a @click="ai">Ai</a>
    </div> -->
    <!-- <el-popover ref="phone" placement="left" trigger="hover">
      <p style="text-align: center;">15037788414</p>
    </el-popover> -->
    <qiangzhiDialog ref="qiangzhiDialog" />
  </div>
</template>
<script>
import userinfo from "@/mixins/userinfo.js"
import { mapState } from "vuex";
import qiangzhiDialog from "@/components/qiangzhiDialog/qiangzhiDialog"
export default {
  components: { qiangzhiDialog },
  mixins: [userinfo],
  data() {
    return {
      list: [
        {
          icon: 'el-icon-service',
          key: 'kefu',
          title: '客服'
        },
        {
          icon: 'el-icon-phone-outline',
          key: 'phone',
          title: '电话'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      userLogList: state => state.userLog.userLogList,
      unreadNum: state => state.im.unreadNum,
    })
  },
  mounted() {
  },
  methods: {
    kefu() {
      this.$im.open()
    },
    quanyi() {
      this.$refs.qiangzhiDialog.onshow()
    },
    ai() {
      if (!this.auth) {

        this.$confirm('体验Ai功能需先进行个人和企业认证, 是否继续?', '中能Ai提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          this.$store.dispatch('user/toAccountAuth')
        })
        return
      }
      if (!this.companyAuth) {
        this.$confirm('体验Ai功能需先进行企业认证, 是否继续?', '中能Ai提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          this.$store.dispatch('user/toAccountCompanyAuth')
        })
        return
      }
      window.ai.showChatBot()
      console.log(window.ai)
      // window.cozeWebSDK.showChatBot();
    }
  },
};
</script>
<style lang='scss'>
.service {
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 4;
  transform: translateY(-50%); /* 上移自身高度的 50% */
  cursor: pointer;

  a {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 56px;
    color: #fff;
    font-size: 24px;
    // text-transform: uppercase;
    /* 强制大写 */
    text-decoration: none;
    font-family: sans-serif;
    box-sizing: border-box;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 30px;
    z-index: 1;
  }

  a:hover {
    animation: animate 8s linear infinite;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }

    100% {
      background-position: 400%;
    }
  }

  a:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }

  a:hover:before {
    filter: blur(20px);
    /*模糊效果*/
    opacity: 1;
    animation: animate 8s linear infinite;
  }

  .btn {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background: #0062ff;
    margin-top: 10px;
    box-shadow: 0px 4px 16px 0px rgba(0, 98, 255, 0.55);
    position: relative;
  }

  &-top {
    width: 56px;
    height: 88px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #0062ff;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 98, 255, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Source Han Sans CN";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    p {
      margin-top: 8px;
    }

    .badge /deep/ .el-badge__content {
      background: #ff0d0d;
    }
  }

  &-bottom {
    margin-top: 10px;
    width: 56px;
    height: calc(144px / 2 * 3);
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #eee;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(38, 38, 38, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-1 {
      &:hover {
        .service-code {
          display: block;
        }
      }

      .service-code {
        position: absolute;
        right: 56px;
        padding-right: 10px;
        box-sizing: content-box;
        background-size: 176px;
        background-repeat: no-repeat;
        display: none;
      }
    }

    &-1,
    &-2 {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #666;
      font-family: "Source Han Sans CN";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      p {
        margin-top: 4px;
      }
    }
  }
}
</style>