<template>
  <div class="login">
    <logo />
    <div class="content">
      <div class="box">
        <div class="box_top">
          <img src="@/static/new/img/login/login_logo.webp" alt="" />
        </div>
        <div class="desc">
          <div class="text">直播公会保驾护航，直降90%的签约风险和维权成本</div>
        </div>
        <passLogin v-show="active === 'passLogin'" />

        <codeLogin v-show="active === 'codeLogin'" />

        <register v-show="active === 'register'" />
      </div>
      <div class="content_b" @click="serviceClick">
        <!-- <img src="@/static/new/img/login/login_icon1.png" alt=""> -->
        <i
          class="el-icon-ali-dengluyekefu"
          style="font-size: 14px; margin-right: 6px"
        ></i>
        遇到问题了？微信客服
      </div>
    </div>
    <service ref="serviceRef" />
  </div>
</template>

<script>
import logo from "./components/logo.vue";
import service from "@/components/service/index.vue";
import passLogin from "./components/passLogin.vue";
import codeLogin from "./components/codeLogin.vue";
import register from "./components/register.vue";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    logo,
    passLogin,
    codeLogin,
    register,
    service,
  },
  name: "login",
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState("login", ["active"]),
  },
  methods: {
    serviceClick() {
      if (this.$refs.serviceRef.show) {
        this.$refs.serviceRef.onHide();
        return;
      }
      this.$refs.serviceRef.onShow();
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  /* background: #66f; */
  /* height: 100%; */
  flex: 1;
  background-image: url(~@/static/new/img/login/login_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.content {
  width: 436px;
  height: 562px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* 通过 transform 调整位置 */
  .box {
    width: 436px;
    height: 512px;
    border-radius: 6px;
    opacity: 1;
    background: #ffffff;

    box-shadow: 2px 3px 18px 0px rgba(0, 0, 0, 0.1);
    padding-top: 20px;

    &_top {
      width: 436px;
      height: 60px;
      display: flex;
      align-items: center;
      padding-left: 34px;

      img {
        width: 126px;
        height: 28px;
      }
    }

    .desc {
      width: 436px;
      height: 44px;
      display: flex;
      align-items: center;
      padding-left: 34px;
      margin-bottom: 20px;

      .text {
        font-size: 14px;
        line-height: 22px;
        color: #999999;
      }
    }
  }

  &_b {
    display: flex;
    align-items: center;
    top: 0px;
    font-size: 14px;
    color: #0062ff;
    height: 50px;
    justify-content: center;
    cursor: pointer;
    // img {
    //   width: 14px;
    //   height: 14px;
    //   margin-right: 6px;
    // }
  }
}
</style>
