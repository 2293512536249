// 签到
<template>
  <div class="signIn">
    <img v-if="$route.name === 'home'" src="@/static/image/signIn/signIn_icon2.png" alt="" @click="signInModeOnshow">
    <img v-else src="@/static/image/signIn/signIn_icon.png" alt="" @click="signInModeOnshow">
    <signInMode ref="signInMode" @sing="signInModeCallback" />
    <sigInSuccess ref="sigInSuccess" />
    <signInSelect ref="signInSelect" @confirm="confirm" @cancel="cancel" />
  </div>
</template>

<script>
import userinfo from "@/mixins/userinfo.js"
import signInMode from './signInMode.vue';
import sigInSuccess from './sigInSuccess.vue';
import signInSelect from './signInSelect.vue';
export default {
  components: {
    signInMode,
    sigInSuccess,
    signInSelect
  },
  mixins: [userinfo],
  data() {
    return {

    };
  },
  created() { },
  computed: {},
  methods: {
    // 签到
    signInModeOnshow() {
      if (!this.user.creator) {
        this.$confirm('仅限法人账号使用', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
        return
      }
      this.$refs.signInMode.onshow()
    },
    // 签到成功
    sigInSuccessOnshow() {
      this.$refs.sigInSuccess.onshow()
    },
    // 选择账号
    signInSelectOnshow() {
      this.$refs.signInSelect.onshow()
    },
    // 签到回调
    signInModeCallback() {
      this.signInSelectOnshow()
    },
    // 选择账号确定
    confirm() {
      this.sigInSuccessOnshow()
    },
    // 选择账号取消
    cancel() {
      this.signInModeOnshow()
    }
  },
};
</script>

<style scoped lang='scss'>
.signIn {
  margin-right: 20px;
  img {
    cursor: pointer;
  }
}
</style>