/*
 * @Author: 曹俊杰
 * @Date: 2022-05-13 19:20:18
 * @Module:
 */

const state = {
  active: "codeLogin",
  agreementState: false,
  agreementRules: false,
};
const mutations = {
  setActive(state, payload) {
    state.active = payload;
  },
  setAgreementState(state, payload) {
    state.agreementState = payload;
  },
  setAgreementRules(state, payload) {
    state.agreementRules = payload;
  },
};
const actions = {
  setActive({ commit }, payload) {
    commit("setActive", payload);
  },
  setAgreementState({ commit }, payload) {
    commit("setAgreementState", payload);
    if (payload) {
      commit("setAgreementRules", false);
    }
  },
  async agreementRules({ commit, state }) {
    if (!state.agreementState) {
      commit("setAgreementRules", true);
      return Promise.reject();
    }
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
