import store from "@/store";
import test from "@/utils/test";
const channelId = "59a71648a042e00da1e588d8d2986359";
// import YSF from '@neysf/qiyu-web-sdk';
let isSdkReady = false;

const im = {
  /* eslint-disable no-inner-declarations */
  int() {
    return new Promise((resolve, reject) => {
      if (!window.ysf) {
        //接入代码在客服工作台「客户接入渠道」里创建生成
        (function (w, d, n, a, j) {
          w[n] =
            w[n] ||
            function () {
              (w[n].a = w[n].a || []).push(arguments);
            };
          j = d.createElement("script");
          j.async = true;
          j.src = `https://qiyukf.com/script/${channelId}.js?hidden=1`;
          d.body.appendChild(j);
        })(window, document, "ysf");

        (function (w, d, n, a, j) {
          w[n] =
            w[n] ||
            function () {
              (w[n].a = w[n].a || []).push(arguments);
            };
          j = d.createElement("script");
          j.async = true;
          j.src = `https://qiyukf.com/script/${channelId}.js?hidden=1`;
          d.body.appendChild(j);
        })(window, document, "ysf");
        resolve();
      } else {
        console.error("SDK加载失败");
        reject("SDK加载失败");
      }
    });
  },
  ready() {
    return new Promise((resolve, reject) => {
      window.ysf("onready", () => {
        this.isSdkReady = true;
        const href = window.ysf("url");
        console.log(321321, href);
        resolve();
      });
    });
  },
  async intIm() {
    !isSdkReady && (await this.int());
    !isSdkReady && (await this.ready());
    !isSdkReady && this.config();
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  //设置用户信息、分流信息
  config() {
    const { uuid: uid, name, phone: mobile, avatar } = store.state.user.user;
    const companyObj = store.getters["user/companyObj"];
    const auth = store.getters["user/auth"];
    const companyAuth = store.getters["user/companyAuth"];
    const data = JSON.stringify([
      { key: "auth", label: "个人实名", value: auth ? "已实名" : "未实名" },
      {
        key: "companyAuth",
        label: "公司实名",
        value: companyAuth ? "已实名" : "未实名",
      },
      {
        key: "companyObj.name",
        label: "公司名",
        value: companyObj.name,
        hidden: test.empty(companyObj.name),
      },
      {
        key: "companyObj.IdNumber",
        label: "公司证件号",
        value: companyObj.idNumber,
        hidden: test.empty(companyObj.idNumber),
      },
      {
        key: "companyObj.representativeName",
        label: "法人代表姓名",
        value: companyObj.representativeName,
        hidden: test.empty(companyObj.representativeName),
      },
      {
        key: "companyObj.representativeIdNumber",
        label: "法人代表证件号",
        value: companyObj.representativeIdNumber,
        hidden: test.empty(companyObj.representativeIdNumber),
      },
      {
        key: "companyObj.representativePhone",
        label: "法人代表手机号",
        value: companyObj.representativePhone,
        hidden: test.empty(companyObj.representativePhone),
      },
    ]);
    window.ysf("config", {
      uid,
      name,
      mobile,
      data,
      success:  (res)=> {
        console.log("success", window.ysf("url"))
        window.ysf("url");
        store.dispatch("im/setUrl",window.ysf("url"));
      }
    });
    // console.log(store.state.user);
    // if (window.CozeWebSDK) {
    //   window.ai = new window.CozeWebSDK.WebChatClient({
    //     config: {
    //       bot_id: "7459376653861863487", // 替换为你的 bot_id
    //     },
    //     componentProps: {
    //       title: "中能AI",
    //     },
    //     // 用户信息
    //     userInfo: {
    //       id: uid,
    //       url: "https://oss.mcn-open.com/" + avatar,
    //       nickname: name,
    //     },
    //     ui: {
    //       base: {
    //         icon: "https://meimengapp.oss-cn-beijing.aliyuncs.com/znsign/logo.png",
    //       },
    //       footer: {
    //         isShow: true,
    //         expressionText: "内容由AI生成，无法确保真实准确，仅供参考。",
    //         //   linkvars: {
    //         //     name: {
    //         //       text: "A",
    //         //       link: "https://www.test1.com",
    //         //     }
    //         //   },
    //       },
    //       asstBtn: {
    //         isNeed: false,
    //       },
    //       chatBot: {
    //         width: 700,
    //       },
    //     },
    //   });
    // } else {
    //   console.error("CozeWebSDK 未正确加载");
    // }
  },
  //设置商品链接
  product(info) {
    window.ysf("product", info);
  },
  // 用户登出
  logoff() {
    window.ysf("logoff");
  },
  // 打开客服聊天窗口
  open(options) {
    if (process.env.VUE_APP_TYPE == "dev") {
      alert("测试服务器客服功能关闭");
      return;
    }
    //  确保用户信息传送成功
    this.config();
    window.ysf("open", options);
    store.dispatch("im/clearUnreadNum");
  },
  //  新窗口打开客服
  url() {
    // console.log()
    if (isSdkReady) {
      const href = window.ysf("url");
      console.log(321321, href);
      window.open(
        href,
        "_blank",
        `width=600,height=700,top=${(screen.height - 400) / 2},left=${
          (screen.width - 900) / 2
        }`
      );
      return href;
    } else {
      alert("sdk尚未加载成功，请稍后再试");
    }
  },
  //  获取当前未读消息
  getUnreadMsg() {
    return window.ysf("getUnreadMsg");
  },
  //获取当前未读消息
  onunread() {
    return new Promise((resolve) => {
      window.ysf("onunread", (res) => {
        resolve(res);
      });
    });
  },
  //获取会话列表
  getConversation() {
    return new Promise((resolve) => {
      window.ysf("getConversation", (res) => {
        resolve(res);
      });
    });
  },
  //设置会话列表新消息通知回调，返回列表
  onConversation() {
    return new Promise((resolve) => {
      window.ysf("onConversation", (res) => {
        resolve(res);
      });
    });
  },
  //设置会话列表新消息通知回调，返回新消息
  onSessionMessage() {
    return new Promise((resolve) => {
      window.ysf("onSessionMessage", (res) => {
        resolve(res);
      });
    });
  },
  geturl() {
    return new Promise((resolve, reject) => {
      const url = window.ysf("url");
      resolve(url);
    });
  },
};
export default im;
