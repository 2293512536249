// 签到选择
<template>
  <div class="signInSelect">
    <el-dialog :show-close="false" :visible.sync="dialogVisible" width="450px" :lock-scroll="false" :modal-append-to-body="false">
      <div class="signInSelect_diaog">
        <div class="signInSelect_header">
          <div class="signInSelect_header_top">选择账号</div>
          <div class="signInSelect_header_bottom">代金劵需发放到指定账号，请选择公司任意一个所属账号</div>
          <div class="el-icon-close close" @click="onclose"></div>
        </div>
        <div class="signInSelect_table">
          <div class="signInSelect_table_header">
            <div class="signInSelect_table_header_item col1">序号</div>
            <div class="signInSelect_table_header_item col2">账号名称</div>
            <div class="signInSelect_table_header_item col3">手机号</div>
            <div class="signInSelect_table_header_item col4"></div>
          </div>
          <div class="signInSelect_table_content">
            <div class="signInSelect_table_item" v-for="(item,index) in list" :key="item.uuid" @click="uuid = item.uuid">
              <div class="col1">{{ index + 1 }}</div>
              <div class="col2">{{ item.name }}</div>
              <div class="col3">{{ item.phone }}</div>
              <div class="col4 icon">
                <svg v-if="item.uuid===uuid" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <circle cx="6" cy="6" r="6" fill="#0062FF" />
                  <circle cx="6" cy="6" r="3" fill="white" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <circle cx="6" cy="6" r="5.5" fill="white" stroke="#EEEEEE" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="signInSelect_footer">
          <div class="total">共{{list.length}}个账号</div>
          <div class="btns">
            <div class="cancel" @click="cancel">取消</div>
            <div class="confirm" @click="confirm">确定</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      list: [],
      uuid: null
    };
  },
  created() {
    this.getAccountColleagueList()
  },
  computed: {


  },
  methods: {
    // 取消 
    cancel() {
      this.onclose()
      this.$emit('cancel')
    },
    // 确定
    confirm() {
      this.putCheckIn()
    },
    onshow() {
      this.dialogVisible = true
    },
    onclose() {
      this.dialogVisible = false
    },
    async getAccountColleagueList() {
      const { data, status, info } = await this.$api.getAccountColleagueList()
      if (status === 200) {
        this.list = data
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    async putCheckIn() {
      if (!this.uuid) {
        this.$message({
          type: 'error',
          message: '请选择账号'
        })
        return
      }
      const { status, info } = await this.$api.putCheckIn({
        accountUuid: this.uuid
      })
      if (status === 200) {
        this.$emit('confirm')
        // this.$message({
        //   type: 'success',
        //   message: info
        // })
        this.onclose()
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    }
  },
};
</script>

<style scoped lang='scss'>
.signInSelect {
  /deep/ .el-dialog {
    background-color: rgba($color: #000000, $alpha: 0);
    box-shadow: none;
    margin-top: 0px !important;

    &__header {
      padding: 0;
    }

    &__body {
      padding: 0;
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }

  &_diaog {
    margin-top: 50px;
    width: 450px;
    height: 463px;
    border-radius: 6px;
    background: #fff;
  }
  &_header {
    height: 83px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    position: relative;
    &_top {
      color: #262626;
      font-family: "Source Sans Pro";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.133px;
    }
    &_bottom {
      color: #666;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .close {
      color: #999;
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  }
  &_table {
    padding: 0 20px;
    .col1 {
      width: 60px;
    }
    .col2 {
      width: 96px;
    }
    .col3 {
      width: 150px;
    }
    .col4 {
      width: 104px;
    }
    &_header {
      height: 50px;
      display: flex;
      align-items: center;
      background: #f3f6f8;
      border-bottom: 1px solid #eee;
      &_item {
        padding-left: 16px;
      }
    }
    &_content {
      height: calc(300px - 50px);
      overflow-y: auto;
    }
    &_item {
      display: flex;
      height: 50px;
      align-items: center;
      padding-left: 16px;
      border-bottom: 1px solid #eee;

      color: #333;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 185.714% */
      .icon {
        display: flex;
        justify-content: flex-end;
      }
      cursor: pointer;
    }
  }
  &_footer {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 43px;
    .total {
      color: #666;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .btns {
      display: flex;
      .cancel {
        width: 80px;
        height: 32px;
        flex-shrink: 0;
        border-radius: 2px;
        border: 1px solid #eee;
        background: #fff;
        color: #666;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.104px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .confirm {
        width: 80px;
        height: 32px;
        flex-shrink: 0;
        border-radius: 2px;
        background: #0062ff;
        color: #fff;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.104px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>