import Vue from 'vue'
import Vuex from 'vuex'
import createVuexAlong from 'vuex-along'
import user from './user'
import help from './help'
import electronic from './electronic'
import documents from './documents'
import userLog from './userLog'
import im from './im'
import equity from './equity'
import insuranceSelect from './insuranceSelect'
import login from './login'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		user,
		electronic,
		help,
		documents,
		userLog,
		im,
		equity,
		insuranceSelect,
		login
	},
	// vuex 状态储存   文档-https://github.com/boenfu/vuex-along#readme
	plugins: [createVuexAlong({
		local: {
			list: ["user", "userLog", "equity", "insuranceSelect", "electronic"],
		}
	})]
})
