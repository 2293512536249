<template>
    <div class="inputBox">
        <div class="inputW">
            <input ref="loginInput" @focus="handleFocus" @blur="handleBlur" :value="value" @input="change"
                :maxlength="maxlength" :type="isPassword ? 'password' : 'text'" />
            <img v-if="password" :src="showPassword ? showIcon : hideIcon" alt="" srcset=""
                @click="showPassword = !showPassword">
            <div v-if="code" @click="getCode" class="code">{{ secondrender }}</div>
        </div>

        <div class="placeholder" @click="forceFocus" :class="{ forcePlaceholder: focusState || value }">{{ placeholder
        }}
        </div>
        <div class="solid" :class="{ solid_error: errorText }">
            <div class="solid_c" :class="{ solid_force: focusState }"></div>
        </div>
        <div class="error">{{ errorText }}</div>
    </div>
</template>

<script>
import showIcon from "@/static/show.svg"
import hideIcon from "@/static/hide.svg"
import second from "@/mixins/second.js"
export default {
    name: 'loginInput',
    mixins: [second],
    props: {
        placeholder: {
            type: String,
            default: '手机号码'
        },
        value: {
            type: String,
            default: ''
        },
        maxlength: {
            type: String,
            default: '20'
        },
        password: {
            type: Boolean,
            default: false
        },
        code: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showIcon,
            hideIcon,
            focusState: false,
            errorText: '',
            showPassword: false,
            secondText: '发送验证码'
        };
    },
    mounted() { },
    computed: {
        isPassword() {
            if (!this.password) {
                return false
            }
            if (this.showPassword) {
                return false
            }
            return true
        }
    },
    methods: {
        change(e) {
            const value = e.target.value;
            this.errorText = ''
            this.$emit('input', value)
        },
        forceFocus() {
            this.$refs.loginInput.focus();
        },
        // 失去焦点时记录
        handleBlur() {
            this.focusState = false;
            this.$emit("onBlur")
        },
        handleFocus() {
            this.focusState = true
            this.$emit("onFocus")
        },
        setError(text) {
            this.errorText = text
        },
        getCode(){
            this.$emit('getCode')
        }
    },
};
</script>

<style scoped lang="scss">
$errorColor: #F45858;

.inputBox {
    height: 100%;
    position: relative;

    .inputW {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        img {
            width: 18px;
            height: 18px;

        }

        .code {
            cursor: pointer;
            font-size: 12px;
            color: #0062FF;
        }
    }

    .placeholder {
        position: absolute;
        left: 0px;
        top: 16px;
        opacity: 1;
        font-size: 12px;
        color: #999999;
        transition: all 0.25s;
    }

    .forcePlaceholder {
        font-size: 10px;
        top: 0;
    }

    input {
        height: 20px;
        margin-top: 16px;
        background: transparent;
        z-index: 2;
        font-size: 14px;
        color: #262626;
        width: 250px;
    }

    .solid {
        height: 1px;
        background: #E3E3E3;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        &_c {
            background: #0062FF;
            height: 1px;
            width: 0%;
            transition: all 0.25s;
        }

        &_force {
            width: 100%;
        }

        &_error {
            background: $errorColor;

            .solid_c {
                background: $errorColor;
            }
        }
    }

    .error {
        margin-top: 2px;
        font-size: 12px;
        font-family: Source Han Sans;
        color: $errorColor;

    }
}
</style>