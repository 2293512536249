<template>
    <div class="formBox">
        <div class="row1">
            手机号注册
        </div>
        <div class="row2">
            <loginInput placeholder="手机号" ref="phoneIpt" v-model="from.phone" maxlength="11" />
        </div>
        <div class="row3">
            <loginInput placeholder="验证码" ref="codeIpt" v-model="from.verifyCode" code maxlength="4"
                @getCode="getCode" />
        </div>
        <div class="row4">
            <agreement />
        </div>
        <el-button type="primary" class="btn" @click="onSubmit" :disabled="disabled"  :loading="loading">注册</el-button>
        <div class="btn2" @click="setActive('codeLogin')">
            返回登录
        </div>
    </div>
</template>

<script>
import mixins from "../mixins.js"
export default {
    name: 'register',
    mixins: [mixins],
    data() {
        return {
            from: {
                phone: '',
                verifyCode: ''
            },
            loading:false
        };
    },
    mounted() { },
    computed: {
        disabled() {
            if (!this.from.phone) return true
            if (!this.from.verifyCode) return true
            return false
        }
    },
    methods: {
        codeApi(pram) {
            return this.$api.postRegisterSmsCode(pram)
        },
        async onSubmit() {
            await this.phoneRules()
            await this.codeRules()
            this.loading = true
            const { info, status, data } = await this.$api.postUserRegister(this.from, false)
            if (status === 200) {
                this.$message({
                    message: info,
                    type: 'success'
                })
                this.setUserInfo(data);
            } else {
                this.$message({
                    message: info,
                    type: 'error'
                })
            }
            this.loading = false
        }
    },
};
</script>

<style scoped lang="scss">
@import './formStyle.scss'
</style>