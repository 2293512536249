export default {
    data() {
        return {
            contractStateList: [    //合同状态
                {
                    label: '签署中',
                    value: 'IN_PROGRESS',
                    color: '#FFE7E7'
                },
                {
                    label: '签署完成',
                    value: 'FINISHED',
                    color: '#E8FAEF'
                },
                {
                    label: '已撤消',
                    value: 'CANCELED',
                    color: '#EEE'
                },
                // {
                //     label: '终止',
                //     value: 'TERMINATED',
                //     color: '#FF0D0D'
                // },
                // {
                //     label: '过期',
                //     value: 'EXPIRED',
                //     color: '#666'
                // },
                // {
                //     label: '拒签',
                //     value: 'REFUSED',
                //     color: '#FF0D0D'
                // }
            ],
            insuranceState: [{   //合同宝状态
                label: '未保障',
                value: 0,
            },
            // {
            //     label: '不可投保',
            //     value: 1,
            // },
            {
                label: '保障中',
                value: 2,
            },
            {
                label: '已到期',
                value: 3,
            },
            {
                label: '启动维权',
                value: 4,
            },
            {
                label: '部分退款',
                value: 5,
            },
            {
                label: '全额退款',
                value: 6,
            }],
            certificateType: [  //出证类型
                {
                    label: '电子版',
                    value: 0
                },
                {
                    label: '纸质版',
                    value: 1
                }
            ],
            certificateState: [
                {
                    label: '未付款',
                    value: 0
                },
                {
                    label: '出证中',
                    value: 1
                },
                {
                    label: '已交付',
                    value: 2
                }
            ],
            transactionType: [  //消费类型
                {
                    label: '法务咨询',
                    value: "1"
                },
                {
                    label: '三要素核验',
                    value: "2"
                },
                {
                    label: '合同',
                    value: "3"
                },
                {
                    label: '合同锁',
                    value: "4"
                },
                {
                    label: '出证',
                    value: "5"
                },
                {
                    label: '课程',
                    value: "6"
                },
                {
                    label: '权益执行',
                    value: "7"
                }
            ],
            payType: [{
                label: '余额',
                value: 1
            },
            {
                label: '支付宝',
                value: 2
            }],
            enforceType: [
                {
                    label: '法院判决',
                    value: 1
                }, {
                    label: '商事仲裁',
                    value: 2
                }
            ],
            subjectType: [{
                label: '法人',
                value: 1
            },
            {
                label: '自然人',
                value: 2
            }],
            //推广收益类型
            rewardRecordType: [
                {
                    label: '首次支付',
                    value: 1
                },
                {
                    label: '充值',
                    value: 2
                },
                {
                    label: '法律咨询',
                    value: 3
                },
                {
                    label: '电子合同',
                    value: 4
                },
                {
                    label: '提现',
                    value: -1
                },
                {
                    label: '提现到余额',
                    value: -2
                }
            ],
            // 代金券类型
            voucherTypeData:[
                {
                    label:'通用代金券',
                    value:0
                },
                {
                    label:'法律咨询-代金券',
                    value:1
                },
                {
                    label:'电子合同-代金券',
                    value:2
                }
            ],
            employeeType:[
                {
                    label: '正常',
                    value: 0
                },
                {
                    label: '停用',
                    value: 1
                }
            ],
            idType:[
                {
                    label: '中国大陆居民身份证',
                    value:'CRED_PSN_CH_IDCARD'
                },
                {
                    label: '香港来往大陆通行证',
                    value:'CRED_PSN_CH_HONGKONG'
                },
                {
                    label: '澳门来往大陆通行证',
                    value:'CRED_PSN_CH_MACAO'
                },
                {
                    label: '台湾来往大陆通行证',
                    value:'CRED_PSN_CH_TWCARD'
                },
                {
                    label: '护照',
                    value:'CRED_PSN_PASSPORT'
                }
            ]

        }
    },
    methods:{
        EnumRender(res, list) {
            if (!list.length) {
                return "--"
            }
            return list.find(item => item.value == res).label
        },
    }
}