<template>
  <div>
    <div class="logo">
      <img src="@/static/new/img/login/login_logo.webp" alt="">
      <div class="solid"></div>
      <div class="text">直播行业法律服务平台</div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'logo',
  data() {
    return {
      tabs:[
        {
          title:'验证码登录'
        }
      ]
    };
  },
  mounted() { },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.logo {
  min-width: 1200px;
  width: 100%;
  height: 90px;
  position: absolute;
  top: 8px;
  padding-left: 60px;
  display: flex;
  align-items: center;

  img {
    width: 150px;
    height: 32px;
  }

  .solid {
    /* 自动布局子元素 */
    position: static;
    left: 166px;
    top: 4px;
    width: 1px;
    height: 24px;
    opacity: 1;

    /* 自动布局 */
    display: flex;
    flex-direction: undefined;
    justify-content: undefined;
    align-items: undefined;
    padding: NaNpx;

    background: #AAAAAA;

    z-index: 1;
    margin: 0 16px;
  }

  .text {
    /* 自动布局子元素 */
    position: static;
    left: 183px;
    top: 5px;
    width: 160px;
    height: 22px;
    opacity: 1;

    font-family: Source Han Sans;
    font-size: 16px;
    // font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;

    font-variation-settings: "opsz" auto;
    font-feature-settings: "kern" on;
    color: #333333;

    z-index: 2;
  }
}


</style>