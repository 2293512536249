<template>
  <div class="box">
    <div class="check" :class="{ shake: isShaking }">
      <el-checkbox :value="agreementState" @change="setAgreementState" /> 我已阅读并同意<a href="https://www.znsign.com/TermsandConditions.html" target="_blank">中能E签用户协议</a>&nbsp;和&nbsp;<a href="https://www.znsign.com/PrivacyPolicy.html" target="_blank">隐私政策</a>
    </div>
    <div class="message" :class="{ show: agreementRules }">
      <div class="message_center">未勾选中能E签用户协议和隐私政策</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: 'agreement',
  data() {
    return {
      show: true,
      isShaking: false
    };
  },
  computed: {
    ...mapState("login", ["agreementState", 'agreementRules'])
  },
  watch:{
    agreementRules(res){
      res&&this.triggerShake()
    }
  },
  methods: {
    ...mapActions("login", ["setAgreementState"]),
    triggerShake() {
      this.isShaking = false
      this.$nextTick(() => {
        this.isShaking = true
        setTimeout(() => {
          this.isShaking = false
        }, 300) 
      })
    }
  },
  mounted() { },
};
</script>

<style scoped lang="scss">
.box {
  position: relative;
  height: 58px;

  .check {
    height: 28px;
    margin-bottom: 30px;
    position: absolute;
    z-index: 2;
    background: #fff;
    width: 100%;
    a{
      cursor: pointer;
      transition: .4s;
      &:hover{
        color: #0062FF;

      }
    }
  }

  .message {
    height: 30px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    transition: all .25s;

    &_center {
      width: 100%;
      padding: 1px 8px;
      font-size: 12px;
      line-height: 22px;
      border-radius: 4px;
      background: #feecec;
      color: #f45858;
    }
  }

  .show {
    top: 30px;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }

    20% {
      transform: translateX(-5px);
    }

    40% {
      transform: translateX(5px);
    }

    60% {
      transform: translateX(-5px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      transform: translateX(0);
    }
  }

  .shake {
    animation: shake 0.3s ease-in-out;
    border-color: red;
  }
}
</style>