<template>
    <div class="formBox">
        <div class="row1">
            <div class="activeBtn" :class="{ gray: active !== 'codeLogin' }" @click="setActive('codeLogin')">验证码登录</div>
            <div class="activeBtn" :class="{ gray: active !== 'passLogin' }" @click="setActive('passLogin')">密码登录</div>
        </div>
        <div class="row2">
            <loginInput placeholder="手机号" v-model="from.phone" maxlength="11" ref="phoneIpt" />
        </div>
        <div class="row3">
            <loginInput placeholder="登录密码" password v-model="from.password" ref="passowrdIpt" />
        </div>
        <div class="row4">
            <agreement />
        </div>
        <el-button type="primary" class="btn" @click="onSubmit" :disabled="disabled" :loading="loading">登录</el-button>

        <div class="btn2 gray " @click="setActive('register')">
            <span class="hoverBlue">没有账号？点击注册</span>
        </div>
    </div>
</template>

<script>
import mixins from "../mixins.js"
export default {
    name: 'passLogin',
    mixins: [mixins],
    data() {
        return {
            from: {
                phone: '',
                password: '',
                type: 2
            }
        };
    },
    mounted() { },
    computed: {
        disabled() {
            if (!this.from.phone) return true
            if (!this.from.password) return true
            return false
        }
    },
    methods: {
        async onSubmit() {
            await this.phoneRules()
            await this.passwordRules()
            await this.agreementRules()
            this.loading = true
            const { info, status, data } = await this.$api.postUserLogin(this.from, false)
            if (status === 200) {
                this.$message({
                    message: info,
                    type: 'success'
                })
                this.setUserInfo(data)
            } else {
                this.$message({
                    message: info,
                    type: 'error'
                })
            }
            this.loading = false
        }
    },
};
</script>

<style scoped lang="scss">
@import './formStyle.scss'
</style>