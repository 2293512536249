import axios from "axios";
import store from "@/store";
import debounce from "@/utils/debounce";
// import Router from '@/router'
import { Loading, Message } from "element-ui";
import queryParams from "@/utils/queryParams";

axios.defaults.timeout = 0;
// http request 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 过滤上送参数为''或者Null
    // if (config.data && config.url !== "api/upload/image" && config.url !== "api/import/invite-state" && config.url !== "api/import/import") {
    //   let params = {}
    //   for (let key in config.data) {
    //     if (config.data[key] || config.data[key] === 0) {
    //       params[key] = config.data[key]
    //     }
    //   }
    //   config.data = params
    // }
    // if (userInfo) {
    //   config.headers['Authorization'] =
    // }
    const { companyUuid, token } = store.state.user.user;
    token && (config.headers["Authorization"] = "Bearer " + token);
    companyUuid && (config.headers["companyUuid"] = companyUuid);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
//http response 响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.resolve(error.response);
  }
);
//check http状态码
function checkStatus(response) {
  if (response && response.data && response.status === 401) {
    store.dispatch("user/logout");
    store.dispatch("userLog/clearUserLog");
    debounce(() =>
      Message({
        message: response.data.msg || "登录过期,请重新登录",
      })
    );
    return response;
  }

  if (
    response &&
    ((response.status >= 200 && response.status < 300) ||
      response.status === 304)
  ) {
    return {
      data: response.data,
      status: 200,
      info: response.data.msg ? response.data.msg : "成功",
    };
  } else {
    return {
      data: response.data,
      status: response.status,
      info: response.data.msg,
    };
  }
}
//check apiCode码
function checkCode(res) {
  return res;
}

// loading
let loadingCount = 0;
let loading;

const startLoading = () => {
  loading = Loading.service({
    lock: true,
    text: "加载中……",
    background: "rgba(0, 0, 0, 0.3)",
  });
};

const endLoading = () => {
  loading.close();
};

const showLoading = () => {
  if (loadingCount === 0) {
    startLoading();
  }
  loadingCount += 1;
};

const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  if (loadingCount === 0) {
    endLoading();
  }
};

export default {
  post(url, data, showLoad = true) {
    showLoad && showLoading();
    return axios({
      method: "post",
      url,
      data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        showLoad && hideLoading();
        return checkStatus(response);
      })
      .then((res) => {
        showLoad && hideLoading();
        return checkCode(res);
      });
  },
  put(url, data) {
    showLoading();
    return axios({
      method: "put",
      url,
      data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        hideLoading();
        return checkStatus(response);
      })
      .then((res) => {
        hideLoading();
        return checkCode(res);
      });
  },
  delete(url, data) {
    showLoading();
    return axios({
      method: "delete",
      url,
      data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        hideLoading();
        return checkStatus(response);
      })
      .then((res) => {
        hideLoading();
        return checkCode(res);
      });
  },
  upload(url, data) {
    showLoading();
    return axios({
      method: "post",
      url,
      data,
    })
      .then((response) => {
        hideLoading();
        return checkStatus(response);
      })
      .then((res) => {
        hideLoading();
        return checkCode(res);
      });
  },
  get(url, data, showLoad = true) {
    showLoad && showLoading();
    return axios({
      method: "get",
      url: url + queryParams(data),
    })
      .then((response) => {
        showLoad && hideLoading();
        return checkStatus(response);
      })
      .then((res) => {
        showLoad && hideLoading();
        return checkCode(res);
      });
  },
};
