const state = {
	unreadList: [],
	unreadNum: null,
	url:''
}
const mutations = {
	setUnreadList(state, payload) {
		state.unreadList = payload
	},
	setUnreadNum(state, payload){
		state.unreadNum = payload
	},
	setUrl(state, payload){
		state.url = payload
	}

}
const getters = {
	isDot(state) {
		return state.unreadList.length ? true : false
	},
	unread(state) {
		return state.unreadList.length ? state.unreadList.length : null
	}
}
const actions = {
	setUnreadList({ commit }, payload) {
		commit('setUnreadList', payload)
	},
	clearUnreadList({ commit }) {
		commit('setUnreadList', [])
	},
	setUnreadNum({ commit }, payload){
		console.log(payload)
		commit('setUnreadNum',payload?payload:null)
	},
	clearUnreadNum({ commit }){
		commit('setUnreadNum',null)
	},
	setUrl({ commit },payload){
		commit('setUrl',payload)
	}
}
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}